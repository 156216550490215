<template>
  <div class="content">
    <!-- dialog nuevo rubro -->
    <v-row justify="center">
      <v-dialog v-model="createrubro" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <div class="col-md-12" align="right">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              class="mb-4"
              v-on="on"
              @click="abrir_create_rubro()"
            >
              Nuevo rubro
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Nuevo rubro</span>
            <v-spacer></v-spacer>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="cerrar_create_rubro()"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-card-title>
          <div v-if="procensando_datos">
            <loader
              object="#4caf50"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="40"
              disableScrolling="false"
              name="box"
            ></loader>
          </div>
          <v-card-text>
            <v-container>
              <!-- @submit.prevent="submit" previene el envio del formulario al hacer Enter en el teclado -->
              <v-form
                @submit.prevent="submit"
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="rubro"
                  :counter="30"
                  :rules="namerubro"
                  label="Rubro"
                  required
                ></v-text-field>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar_create_rubro()">
              Cerrar
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="dialog"
              color="blue darken-1"
              text
              @click="guardar_rubro()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Rubros</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->

            <section v-else>
              <!-- DATOS DE LA TABLA -->
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="find_in_page"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :search="search"
                :headers="headers"
                :items="desserts"
                :loading="cargando"
                :items-per-page="rowsPerPage"
                :footer-props="footerProps"
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-0 trow"
              >
                <!-- ACCIONES BOTONES -->
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <!-- modal editar cliente  -->
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Editar rubro</span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>
                        <div v-if="procensando_datos">
                          <loader
                            object="#4caf50"
                            color1="#ffffff"
                            color2="#17fd3d"
                            size="5"
                            speed="2"
                            bg="#343a40"
                            objectbg="#999793"
                            opacity="40"
                            disableScrolling="false"
                            name="box"
                          ></loader>
                        </div>
                        <v-form ref="form" v-model="valid" lazy-validation>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="12" md="12">
                                  <v-text-field
                                    v-model="rubroedit"
                                    label="Rubro"
                                    :counter="30"
                                    :rules="namerubro"
                                    required
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="actualizar"
                            >
                              Actualizar
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!-- modal eliminar -->
                    <v-dialog v-model="dialogDelete" max-width="400px">
                      <v-card>
                        <v-card-title
                          class="text-h5 justify-center"
                          v-text="rubro_rubro"
                        ></v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >Aceptar</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon color="orange" @click="editItem(item)">
                    create
                  </v-icon>
                </template>

                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
                <!--END  BOTONES DE LA TABLA -->
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Rubros } from "../../services/rubros.js";
export default {
  name: "Rubros",
  data: () => ({
    cargando: false,
    dialog: false,
    dialogDelete: false,
    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    //loaders para procesar datos dee envio
    procensando_datos: false,
    // Filter models.
    search: "",
    headers: [
      { text: "Rubro", value: "rubro" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    // Estas propiedades customizan el footer por default(valores por defecto del datatable)
    footerProps: {
      "items-per-page-options": [],
      "items-per-page-text": "",
      "prev-icon": "",
      "next-icon": "",
      "page-text": "",
    },

    // paginacion api
    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },
    //  numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    rowsPerPage: 10,

    desserts: [],

    // campos para nuevo rubro
    createrubro: false,
    rubro: "",

    rubro_rubro: "",
    rubro_id: "",
    // campos a actualizar
    rubroedit: "",
    // validaciones
    valid: false,
    namerubro: [
      (v) => !!v || "Rubro es requerido",
      (v) => (v && v.length <= 30) || "Rubro no debe superar los 30 caracteres",
    ],
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.getdatos();
  },

  methods: {
    submit() {
      this.guardar_rubro();
    },
    //listar los rubros
    getdatos: function() {
      const params = {
        Number: this.pagination.page,
        Size: this.rowsPerPage,
      };
      this.cargando = true;
      Rubros.getRubros(params)
        .then((response) => {
          if (response.data.data == null) {
            this.desserts = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.desserts = response.data.data;
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },
    // paginar
    onPageChange() {
      this.getdatos();
    },
    // registros por paginas
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.pagination.page = 1;
      this.getdatos();
    },

    async guardar_rubro() {
      var payload = {
        rubro: this.rubro,
      };
      this.validate();
      if (this.res === true) {
        this.error = false;
        this.procensando_datos = true;
        await Rubros.nuevo_rubro(payload)
          .then(() => {
            this.getdatos();
            this.$toastr.s("El rubro se creo correctamente");
            this.createrubro = false;
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    // dialog nuevo rubro
    abrir_create_rubro() {
      this.createrubro = true;
    },

    // cerrar dialog nuevo rubro
    cerrar_create_rubro() {
      this.rubro = "";
      this.reset();
      this.createrubro = false;
    },

    // reset de validaciones de campos
    reset() {
      this.$refs.form.reset();
    },

    //consultar los datos del rubro a modificar
    editItem(item) {
      this.consultar_rubro(item);
      this.dialog = true;
    },

    //consultar el id del rubro a eliminar
    deleteItem(item) {
      this.consultar_rubro(item);
      this.dialogDelete = true;
    },

    // confirmar el rubro a eliminar
    deleteItemConfirm() {
      // falta endpoint eliminar rubro
      //  const params  = {
      //     id : this.cliente_id,
      //     }
      //     this.cargando = true;
      //      this.axios.delete("https://dev-back-pagos.telco.com.ar/administracion/cliente",{params})
      //     .then( response => {
      //         // mensaje de exito
      //         this.$toastr.s("El cliente se elimino con éxito");
      //         // actualziar tabla de clientes
      //          this.getdatos();
      //         }).catch(error => {
      //         this.errored = true
      //         var data = error.response.data;
      //         this.error = true;
      //         this.$toastr.e(data.message);
      // }).finally(() => this.cargando = false);
      // this.closeDelete()
    },

    //confirmar actualizar los datos del cliente
    async actualizar() {
      var payload = {
        id: this.rubro_id,
        rubro: this.rubroedit,
      };
      this.validate();
      if (this.res === true) {
        this.error = false;
        this.loading = true;
        this.dialog = true;
        this.procensando_datos = true;
        await Rubros.updateRubro(payload)
          .then(() => {
            this.dialog = false;
            this.$toastr.s("El rubro se actualizo correctamente");
            // actualziar tabla de clientes
            this.getdatos();
            this.procensando_datos = false;
            this.close();
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.loading = false;
            this.$toastr.e(data.message);
            this.dialog = false;
            this.procensando_datos = false;
          })
          .finally(() => (this.loading = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // validar formulario actualizar
    validate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },

    //consultar cliente por id
    consultar_rubro: function(item) {
      const params = {
        id: item.id,
      };
      Rubros.getRubro(params)
        .then((response) => {
          this.rubro_rubro =
            "¿ Desea eliminar el rubro " + response.data.rubro + "?";
          //cargar los datos en el modal
          this.rubro_id = response.data.id;
          this.rubroedit = response.data.rubro;
        })
        .catch((error) => {
          var data = error.response.data;
          //mostrar mensaje de error en caso que la variable sea true(tenga datos)
          this.error = true;
          this.msg = data.message;
          this.$toastr.e(data.message);
        });
    },
  },
};
</script>
<style scoped>
/* efecto tabla de cliente */
/* .trow {
  transition: transform 0.3s;
}
.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
} */

a {
  color: #f7f7f7;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
</style>
